import { useEffect, useState } from "react";

/**
 * Custom hook to intercept clicks on external links and show an alert.
 * @param {function} onExternalLinkClick - Callback function when an external link is clicked.
 */

const useExternalLinkInterceptor = (onExternalLinkClick) => {
  useEffect(() => {
    const handleExternalLinkClick = (event) => {
      const target = event.target;
      if (target.tagName === "A" && target.origin !== window.location.origin) {
        const isExternalLink =
          !target.href.includes(window.location.origin) &&
          !target.href.includes("mailto:") &&
          !target.href.includes("tel:") &&
          !target.href.includes("libertyadvantageplan.com");

        if (isExternalLink) {
          event.preventDefault();
          onExternalLinkClick(target.href);
        }
      }
    };

    document.addEventListener("click", handleExternalLinkClick);

    return () => {
      document.removeEventListener("click", handleExternalLinkClick);
    };
  }, [onExternalLinkClick]);
};

export default useExternalLinkInterceptor;
